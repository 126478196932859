import { createWebHistory, createRouter } from "vue-router";
import Auth from './services/Auth'
const routes =  [
  {
    path: "/",
    alias: "/login",
    name: "login",
    meta: {
      title: "Login",
      allowAnonymous: true
    },
    component: () => import("./components/Login"),
    props: true
  },
  {
    path: "/calendrier",
    alias: "/calendrier",
    name: "calendrier",
    meta : {
      title: "Calendrier Mois - Extranet Non Arjel"
    },
    component: () => import("./components/Calendrier"),
    props: true
  },
  {
    path: "/calendrier2",
    alias: "/calendrier2",
    name: "calendrier2",
    meta : {
      title: "Calendrier Semaine - Extranet Non Arjel"
    },
    component: () => import("./components/Calendrier2"),
    props: true
  },
  {
    path: "/programme",
    alias: "/programme",
    name: "programme",
    meta : {
      title: "Programme Réunion - Extranet Non Arjel"
    },
    component: () => import("./components/Programme"),
    props: true
  },
  { 
    path: "/reunion",
    name: "reunion",
    meta : {
      title: "Réunion"
    },
    component: () => import("./components/Reunion"),
    props: true
  },
  {
    path: "/course",
    name: "course",
    meta : {
      title: "Course - Extranet Non Arjel"
    },
    component: () => import("./components/Course"),
    props: true
  },
  {
    path: "/jour",
    name: "jour",
    meta : {
      title: "Réunion du jour - Extranet Non Arjel"
    },
    component: () => import("./components/Jour"),
    props: true
  },
];




const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && Auth.isLoggedIn()) {
    next({ path: '/programme' })
  }
  else if (!to.meta.allowAnonymous && !Auth.isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }  
})

export default router