import axios from "axios";

export default
axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-type": 'application/json ; charset=iso-8859-1',
    "Access-Control-Allow-Origin" : '*',
    'Accept' : 'application/json', 
    'Content-Type': 'application/json',
  },
});