<template>
  <div id="app">
    <nav id="nav-b" class="navbar navbar-dark bg-dark sticky-top">
        <router-link class="custom-logo" to="/">
        <img style="height:30px!important; width:100%;margin-bottom: 26px;" src="./assets/logo-letrot.png" alt=""/>
 
        <img style="height:43px; width:100%;margin-bottom: 25px;" src="./assets/logo-fg.png" alt=""/>
        </router-link>
        <div class="web-name">
          <p>{{setEnv(this.env)}}</p>
        </div>

        <ul class="custom-user">
          <div v-if="logged">
            <a class="nav-link" style="color:white" href="#" v-on:click="logout()">Déconnecter</a>
          </div>
        </ul>
    </nav>
  <div class="container-fluid">
      <div class="row">
        <!-- <nav class="col-md-2 d-none d-md-block bg-light sidebar">
          <div class="sidebar-sticky">
            <ul class="nav flex-column">
                        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                          Menu
                        </h6>
                          <li class="nav-item">
                            <router-link to="/calendrier" class="nav-link" ><i class="bi bi-calendar"></i> Calendrier</router-link>
                          </li>
            </ul>
          </div>
        </nav> -->
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Auth from './services/Auth';
export default {
  name: "app",
  data() {
    return {
      env: "",
      logged: "",
    };
  },
  watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title + ' - ' + process.env.NODE_ENV || 'Some Default Title';
                this.env = process.env.VUE_APP_ENV
                this.logged = this.loggedIn()
            }
        },
  },
  computed: {

  },
  mounted(){
    this.setEnv(this.env)
  },
  methods:{
    logout(){
      Auth.logoutUser()
    },
    loggedIn(){
      return Auth.isLoggedIn();
    },
    setEnv(en){
      if (en === 'dev'){
        return "dev.LesCoursesLocales"
      }else if (en === 'recette'){
        return "recette.LesCoursesLocales"
      }else if (en === 'prod'){
        return "prod.LesCoursesLocales"
      }else if (en === 'test'){
        return "test.LesCoursesLocales"
      }
    }
  },
};
</script>

<style scoped>

#nav-b {
  display: flex;
  height: 3.5rem;
  position: sticky;
  align-items: center;
  top: 0;
}
#app {
  display: flex;
  height: inherit;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
  height: 100vh;
  background: rgb(232,232,232,0.6);
}
html, body {
    height: 100%;
}
.web-name{
  display: flex;
  align-items: center;
  justify-content: center;
}
.web-name p{
  font-style: italic;
  align-items: center;
  padding: 0 !important;
  font-size: 20px;
  color: white!important;
  margin-top: -10px;
}
</style>