import decode from 'jwt-decode'
import axios from 'axios'
import http from "../http-common";
//const REST_ENDPOINT = 'http://172.16.160.213:3000'
const AUTH_TOKEN_KEY = 'authToken'

class Auth {

loginUser(credentials) {
  return http.post('/signin', credentials)
}


logoutUser() {
    this.clearAuthToken()
}

setAuthToken(token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)    
}

clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

isLoggedIn() {
    let authToken = this.getAuthToken()
    return !!authToken && !this.isTokenExpired(authToken)
}

 getUserInfo() {
    if (this.isLoggedIn()) {
        return decode(this.getAuthToken())
    }
}

 getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }
  
    let date = new Date(0)
    date.setUTCSeconds(token.exp)
  
    return date
}
  
 isTokenExpired(token) {
    let expirationDate = this.getTokenExpirationDate(token)
    return expirationDate < new Date()
}

}
  
export default new Auth();